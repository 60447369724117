import BaseNetwork from "./base-network";
import { APIKEY, LinkMethod, OAUTH_2 } from "../../constant/link-method";
import {NETWORK, LINE_ADS} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";

class LineAds extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Line Ads";

  getNetworkIdentifier = (): NETWORK => LINE_ADS;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = ()  => ADS;

  isETLActivated = () => false;

  alertOptions = () =>  [];

}

export default LineAds;
