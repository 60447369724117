import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import _, { pickBy } from "lodash";
import { useGetAllNetworkAuthorization } from "../../../../hooks/network-authorization";
import DataStreamModel from "../../../../services/models/http/data-connector/data-stream/data-stream";
import Button from "../../../../ui/components/button/button";
import NetworkProvider from "../../../../config/network/network-provider";
import {
  DataStreamJob as HTTPDataStreamJob
} from "../../../../services/models/http/data-connector/data-stream/data-stream-job";
import { classNames } from "../../../../services/helper";

export type DataStreamJob = {
  authorizations: Array<{
    authorizationId: number,
    id: string,
  }>,
  networkAuthorizationId: number | undefined
};


export const Validation = Yup.object().shape({
  authorizations: Yup.array().required("You need to set at least one accounts.")
});

type Props = {
  onSubmit: (data: Partial<DataStreamJob>) => void,
  dataStream: DataStreamModel,
  defaultValue?: Partial<HTTPDataStreamJob>,
  saving?: boolean
}

function getInitialValues(defaultValue: Partial<HTTPDataStreamJob> | undefined, initialValues: {
  authorizations: any[];
  networkAuthorizationId: undefined | number
}) {
  const value = initialValues;
  if (defaultValue && defaultValue.sources) {
    value.authorizations = defaultValue.sources.map(elem => ({ id: elem.accountId, authorizationId: elem.authorization.id }));
  }

  if(defaultValue && defaultValue.networkAuthorization){
    value.networkAuthorizationId = defaultValue.networkAuthorization.id;
  }

  return value;
}

const StepTwo = ({ onSubmit, dataStream, defaultValue, saving }: Props) => {
  const { t } = useTranslation();
  const [typeOfDump, setTypeOfDump] = useState(defaultValue?.networkAuthorization ? "authorization" : "account");
  const [error, setError] = useState(null);
  const initialValues = {
    authorizations: [],
    networkAuthorizationId: undefined
  };
  const destination = useGetAllNetworkAuthorization();

  const optionsDestination = useMemo(() => {
    return destination.data?.data?.filter(destination => destination.getPlatform() === dataStream.getPlatform()).map((destinationItem) => ({
      value: destinationItem.getId(),
      label: destinationItem.getName()
    }));
  }, [destination.data]);
  const optionsAccounts = useMemo(() => {
    console.log(destination);
    if (!destination.data) {
      return [];
    }
    const accounts = destination.data?.data
      ?.filter(
        (destinationItem) => destinationItem.getPlatform() === dataStream.getPlatform()
      )
      .reduce<{ value: string; label: string; authorization_id: number; }[]>((acc, curr) => acc.concat(
        curr.getAvailableDataSource().map((dataSource) => ({
          value: dataSource.accountId,
          label: dataSource.name,
          authorization_id: curr.getId()
        }))
      ), []);
    return _.uniqBy(accounts, "value");
  }, [destination.data]);

  const formik = useFormik<DataStreamJob>({
    initialValues: getInitialValues(defaultValue, initialValues),
    validationSchema: Validation,
    onSubmit: async (values) => {
      setError(null);
      try {
        onSubmit(values);
      } catch (e) {
        // @ts-ignore
        setError(e.message);
      }
    }
  });

  useEffect(() => {
    if(typeOfDump === "account"){
      formik.setFieldValue("networkAuthorizationId", undefined);
    }else{
      formik.setFieldValue("authorizations", []);
    }
  }, [typeOfDump]);
  return (
    <div>
      <div className="space-y-6">
        <form onSubmit={formik.handleSubmit}>
          <div className="bg-white shadow sm:rounded-md sm:overflow-hidden divide-y divide-y-2">
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3
                  className="text-lg leading-6 font-medium text-gray-900">{t("Put the accounts your want to work on.")}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t("You could always add more accounts later.")}
                </p>
              </div>
            </div>
            <div className="py-6 px-4 space-y-6 sm:p-6">
              <fieldset
                className={classNames("border border-dashed border-gray-300 px-3 pb-3", typeOfDump === "account" ? "" : "opacity-75 bg-gray-100")}
                disabled={typeOfDump === "authorization"}>
                <legend className="px-3"><input type="checkbox"
                                                onChange={e => e.target.checked && setTypeOfDump("account")}
                                                checked={typeOfDump === "account"} /> <label
                  className="text-sm font-medium text-gray-700">
                  Select account for
                  your <b>{`${NetworkProvider.getByPlatform(dataStream.getPlatform()).getName()}`}</b>.
                </label></legend>
                <div className={classNames("py-3")}>
                  <Select
                    isLoading={destination.isLoading}
                    isMulti
                    menuPortalTarget={document.body}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "authorizations",
                        newValue.map((elem) => ({
                          id: elem.value,
                          authorizationId: elem.authorization_id
                        }))
                      );
                    }}
                    defaultValue={optionsAccounts.filter((account) =>
                      formik.values.authorizations.some(
                        (selected) => selected.id === account.value
                      )
                    )}
                    value={optionsAccounts.filter((account) =>
                      formik.values.authorizations.some(
                        (selected) => selected.id === account.value
                      )
                    )}
                    name="authorizations"
                    options={optionsAccounts}
                    classNamePrefix="react-select"
                  />
                </div>
              </fieldset>
              <fieldset
                className={classNames("border border-dashed border-gray-300 px-3 pb-3", typeOfDump === "authorization" ? "" : "opacity-75 bg-gray-100")}
                disabled={typeOfDump === "account"}>
                <legend className="px-3"><input type="checkbox" checked={typeOfDump === "authorization"}
                                                onChange={e => e.target.checked && setTypeOfDump("authorization")} />
                  <label
                    className="text-sm font-medium text-gray-700">
                    Select all accounts for
                    your <b>{`${NetworkProvider.getByPlatform(dataStream.getPlatform()).getName()}`}</b> authorization.
                  </label></legend>
                <div className="py-3">
                  <Select
                    isLoading={destination.isLoading}
                    menuPortalTarget={document.body}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "networkAuthorizationId",
                        newValue?.value
                      );
                    }}
                    defaultValue={optionsDestination?.find((account) =>
                      formik.values.networkAuthorizationId === account.value
                    )}
                    value={optionsDestination?.find((account) =>
                      formik.values.networkAuthorizationId === account.value
                    )
                    }
                    name="destination"
                    options={optionsDestination}
                    classNamePrefix="react-select"
                  />
                </div>
              </fieldset>
            </div>
            <div className="py-6 px-4 flex justify-end items-center">
              {
                error ? <div className="text-red-500 mr-4">
                  {error}
                </div> : null
              }
              <div>
                <Button disabled={formik.values.authorizations.length === 0 && formik.values.networkAuthorizationId === null} loading={saving} type="primary"
                        htmlType="submit">
                  {t("Save")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepTwo;
