import BaseNetwork from "./base-network";
import { APIKEY, LinkMethod } from "../../constant/link-method";
import {NETWORK, XANDR} from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";

class Xandr extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Xandr";

  getNetworkIdentifier = (): NETWORK => XANDR;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = ()  => ADS;

  isETLActivated = () => false;

  alertOptions = () =>  [];

}

export default Xandr;
