import React, { useEffect, useMemo, useState } from "react";
import { object, string, number, date, array, boolean } from 'yup';
import { useFormik } from "formik";
import Select from 'react-select';
import _ from "lodash";
import { PlusIcon } from "@heroicons/react/solid";
import { FILTER_FORM, QUERY_FORM, STEP } from "./type";
import {
  useGetAllNetworkAuthorization,
  useGetAllNetworkAuthorizationInformation
} from "../../../hooks/network-authorization";
import { useMe } from "../../../hooks/user";
import { useConfigField, useGetField, usePostSaveQuery, usePutSaveQuery } from "../../../hooks/api";
import { NETWORK } from "../../../constant/network";
import Async from "../../../ui/helper/async";
import NetworkProvider from "../../../config/network/network-provider";
import NetworkAuthorizationInformation
  from "../../../services/models/http/network-authorization/network-authorization-information";
import { Collapse } from "../../../ui/components/collapse";
import Source from "./components/source";
import Button from "../../../ui/components/button/button";
import { ALL_DATE, toString } from "./index";
import Filter from "./components/filter";
import FieldDTO from "../../../services/models/http/google-datastudio/field-dto";
import Modal from "../../../ui/components/modal";
import { NotificationManager } from "../../../ui/components/notification/notification";
import SavedAPIModel from "../../../services/models/http/data-connector/api/api";
import { useQueryClient } from "react-query";

const newFormFilterField = (): FILTER_FORM => ({
    field: null,
    type: null,
    value: null
  } as unknown as FILTER_FORM);

export const validation = object({
  source: string().required(),
  format: string().required(),
  accounts: array()
    .of(
      object({
        id: string(),
        authorization_id: number(),
      })
    )
    .min(1),
  metrics: array().of(string()).test(
      'metrics-or-dimensions',
      'You have to add at least one metric or dimension.',
      (value, testContext) => {
        const { metrics, dimensions } = testContext.parent;
        return (metrics && metrics.length > 0) || (dimensions && dimensions.length > 0);
      }
  ),
  dimensions: array().of(string()).test(
      'metrics-or-dimensions',
      'You have to add at least one metric or dimension.',
      (value, testContext) => {
        const { metrics, dimensions } = testContext.parent;
        return (metrics && metrics.length > 0) || (dimensions && dimensions.length > 0);
      }
  ),
  date: string().required(),
  custom_date: object().shape({
    start: string(),
    end: string(),
  }).when('date', {
    is: 'CUSTOM',
    then: object({
      start: string().required('Start date is required when date is CUSTOM'),
      end: string().required('End date is required when date is CUSTOM'),
    }),
    otherwise: object().nullable(),
  }),
  filters: array().of(
    object({
      field: string(),
      type: string(),
      value: string().nullable(),
    })
  ),
  options: object({
    combine_data: boolean(),
  }),
});

function defaultForm(initialValues?: QUERY_FORM): QUERY_FORM {
  return {
    id: initialValues && _.get(initialValues, 'id') ? initialValues.id : null,
    format: initialValues && _.get(initialValues, 'format') ? initialValues.format : null,
    source:
      initialValues && _.get(initialValues, 'source')
        ? initialValues.source
        : null,
    accounts:
      initialValues && _.get(initialValues, 'accounts')
        ? initialValues.accounts
        : [],
    metrics:
      initialValues && _.get(initialValues, 'metrics')
        ? initialValues.metrics
        : [],
    dimensions:
      initialValues && _.get(initialValues, 'dimensions')
        ? initialValues.dimensions
        : [],
    date:
      initialValues && _.get(initialValues, 'date')
        ? initialValues.date
        : null,
    custom_date:
      initialValues && _.get(initialValues, 'custom_date')
        ? initialValues.custom_date
        : {},
    filters:
      initialValues && _.get(initialValues, 'filters')
        ? initialValues.filters
        : [],
    options:
      initialValues && _.get(initialValues, 'options')
        ? initialValues.options
        : {

        },
  } as QUERY_FORM;
}

type Props = {
  initialValues?: QUERY_FORM;
  onChange: (query: QUERY_FORM) => void,
  isValid: boolean,
  query? : SavedAPIModel,
  onReset: () => void
};

const ModalSaveQuery = ({ loading, query, open, onSave, onClose}: { loading: boolean, query: {name:string|undefined}, open:boolean, onSave: (name:string) => void, onClose: () => void }) => {
  const [name, setName] = useState<string|undefined>(query.name);
  return <Modal open={open} onClose={onClose}>
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-gray-800">Save the query</h2>
      <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name :
          </label>
          <div className="mt-1">
            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              name="name"
              id="name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button
            type="primary-purple"
            loading={loading}
            onClick={() => {
              if(name) {
                onSave(name);
                setName(undefined);
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
  </Modal>
}
const APIForm = (props:Props) => {
  const destination = useGetAllNetworkAuthorization();
  const destinationInformation = useGetAllNetworkAuthorizationInformation();
  const [open, setOpen] = useState(false);
  const [savingApiQuery, setSavingApiQuery] = useState(false);
  const [step, setStep] = useState<STEP | null>(
    _.get(props.initialValues, 'source') ? 'account' : 'source'
  );

  const saveApiQuery = usePostSaveQuery();
  const savePutApiQuery = usePutSaveQuery();

  const formik = useFormik<QUERY_FORM>({
    initialValues: defaultForm(props.initialValues),
    validationSchema: validation,
    onSubmit: (values) => {
      console.log("Values", values);
    },
  });

  const onReset = () => {
    const query = {
      format: formik.values.format
    } as QUERY_FORM;
    formik.resetForm({
      values: defaultForm(query),
    });
    setStep('source');
    props.onReset();
  }

  const queryClient = useQueryClient();
  const onSave = async (name:string) => {
    try{
      setSavingApiQuery(true);
      if(!props.query) {
        await saveApiQuery.mutateAsync({
          name,
          json: formik.values
        });
      }else{
        await savePutApiQuery.mutateAsync({
          id: props.query.getId(),
          name,
          json: formik.values
        });
      }
      await queryClient.invalidateQueries(['get-save-query'])
      setOpen(false);
      NotificationManager.success('The query has been saved', 'You can reuse the query as you want.');
    }catch (e) {
      NotificationManager.error('Unable to save the query', 'An error occurred. Please try again.');
      console.log(e);
    }finally {
      setSavingApiQuery(false);
    }
  }


  useEffect(() => {
    props.onChange(formik.values)
  }, [formik.values]);

  console.log(formik.values.accounts);
  const fields: ReturnType<typeof useGetField> | null = useGetField(
    formik.values.source as NETWORK,
    formik.values.accounts.length === 1 ? `${formik.values.accounts[0].authorization_id}|${formik.values.accounts[0].id}` : null
  );



  const configFields: ReturnType<typeof useConfigField> | null = useConfigField(
    formik.values.source as NETWORK
  );


  const handleStepChange = (stepToGo: STEP) => () => {
    if (stepToGo === step) {
      setStep(null);
    } else {
      setStep(stepToGo);
    }
  };

  useEffect(() => {
    if (!formik.values.source || _.get(props.initialValues, 'source')) {
      return;
    }
    const query = {
      source: formik.values.source,
      format: formik.values.format
    } as QUERY_FORM;
    formik.resetForm({
      values: defaultForm(query),
    });
  }, [formik.values.source]);

  const optionsMetrics = useMemo(() => {
    if (!fields.data) {
      return [];
    }
    return fields.data?.data
      ?.filter((metric) => metric.getType() === 'metric')
      .map((metric) => ({
        value: metric.getFieldID(),
        label: metric.getName(),
      }));
  }, [fields.data]);

  const haveMetric = optionsMetrics.length > 0;

  const optionsAccounts = useMemo(() => {
    if (!destination.data || !formik.values.source) {
      return [];
    }
    const accounts = destination.data?.data
      ?.filter(
        (destinationItem) => destinationItem.getPlatform() === formik.values.source
      )
      .reduce<{ value: string; label: string; authorization_id: number; }[]>((acc, curr) => acc.concat(
          curr.getAvailableDataSource().map((dataSource) => ({
            value: dataSource.accountId,
            label: `${dataSource.name} - ${dataSource.accountId}`,
            authorization_id: curr.getId(),
          }))
        ), []);
    return _.uniqBy(accounts, 'value');
  }, [destination.data, formik.values.source]);
  const optionsDimensions = useMemo(() => {
    if (!fields.data) {
      return [];
    }
    return fields?.data?.data
      ?.filter((metric) => metric.getType() === 'dimension')
      .map((metric) => ({
        value: metric.getFieldID(),
        label: metric.getName(),
      }));
  }, [fields.data]);


  useEffect(() => {
    if(optionsDimensions?.length > 0){
      formik.setFieldValue('dimensions', formik.values.dimensions.filter((dimension) => optionsDimensions.some((option) => option.value === dimension)));
    }
    if(optionsMetrics?.length > 0){
      formik.setFieldValue('metrics', formik.values.metrics.filter((metric) => optionsMetrics.some((option) => option.value === metric)));
    }
  }, [optionsDimensions, optionsMetrics]);
  return (
    <div>
      <Async {...destinationInformation}>
        <Async {...destination}>
          <form>
            {!_.get(props.initialValues, 'query.source') ? (
              <Collapse
                open={step === 'source'}
                header="Sources"
                subHeader={
                  formik.values.source
                    ? NetworkProvider.getByPlatform(
                      formik.values.source
                    )?.getName()
                    : null
                }
                onClick={handleStepChange('source')}
              >
                {destinationInformation.data?.data
                  .filter((elem : NetworkAuthorizationInformation) => NetworkProvider.getByPlatform(elem.getType()))
                  .map((elem) => (
                    <Source
                      onClick={(value) => {
                        formik.setFieldValue('source', value);
                        setStep('account');
                      }}
                      accountAvailable={destination.data?.data
                        .filter(
                          (destinationItem) =>
                            destinationItem.getPlatform() === elem.getType()
                        )
                        .reduce((acc, curr) => acc + curr.getAvailableDataSource().length, 0)}
                      source={NetworkProvider.getByPlatform(elem.getType())}
                      key={elem.getType()}
                    />
                  ))}
              </Collapse>
            ) : null}
            <Collapse
              open={step === 'account'}
              header="Select account(s)"
              subHeader={`${formik.values.accounts.length} account(s)`}
              onClick={handleStepChange('account')}
            >
              <div className="py-2 px-4">
                <label
                  htmlFor="accounts"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Accounts :
                </label>
                <Select
                  isMulti
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      'accounts',
                      newValue.map((elem) => ({
                        id: elem.value,
                        authorization_id: elem.authorization_id,
                      }))
                    );
                  }}
                  defaultValue={optionsAccounts.filter((account) =>
                    formik.values.accounts.some(
                      (selected) => selected.id === account.value
                    )
                  )}
                  name="accounts"
                  options={optionsAccounts}
                  classNamePrefix="react-select"
                />
                <div className="flex justify-end mt-4">
                  <Button
                    type="primary-purple"
                    size="x-small"
                    onClick={() => {
                      setStep(haveMetric ? 'metric' : 'dimension');
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Collapse>
            {haveMetric ? (
              <Collapse
                open={step === 'metric'}
                header="Metrics"
                subHeader={`${formik.values.metrics.length} metric(s)`}
                onClick={handleStepChange('metric')}
              >
                <div className="py-2 px-4">
                  <label
                    htmlFor="metrics"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Metrics :
                  </label>
                  <Select
                    isMulti
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        'metrics',
                        newValue.map((elem: any) => elem.value)
                      );
                    }}
                    value={formik.values.metrics.map(selected => optionsMetrics.find((metric) => selected === metric.value))}
                    defaultValue={formik.values.metrics.map(selected => optionsMetrics.find((metric) => selected === metric.value))}
                    isLoading={fields?.isLoading}
                    name="metrics"
                    options={optionsMetrics}
                    classNamePrefix="react-select"
                  />
                  <p className="text-gray-500 text-xs py-1">
                    If a metric is not available, look on dimensions. Sometimes
                    some metrics are considered as a dimensions by the API.
                  </p>
                  <div className="flex justify-end mt-1">
                    <Button
                      type="primary-purple"
                      size="x-small"
                      onClick={() => {
                        setStep('dimension');
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </Collapse>
            ) : null}
            <Collapse
              open={step === 'dimension'}
              header="Dimensions"
              subHeader={`${formik.values.dimensions.length} dimension(s)`}
              onClick={handleStepChange('dimension')}
            >
              <div className="py-2 px-4">
                <label
                  htmlFor="dimensions"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Dimensions :
                </label>
                <Select
                  isMulti
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      'dimensions',
                      newValue.map((elem: any) => elem.value)
                    );
                  }}
                  value={formik.values.dimensions.map(selected => optionsDimensions.find((dimension) => selected === dimension.value ))}
                  defaultValue={formik.values.dimensions.map(selected => optionsDimensions.find((dimension) => selected === dimension.value ))}
                  isLoading={fields?.isLoading}
                  name="dimensions"
                  options={optionsDimensions}
                  classNamePrefix="react-select"
                />
                <div className="flex justify-end mt-4">
                  <Button
                    type="primary-purple"
                    size="x-small"
                    onClick={() => {
                      setStep('date');
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Collapse>
            <Collapse
              open={step === 'date'}
              header="Date range"
              subHeader={
                formik.values.date
                  ? `${toString(formik.values.date)}`
                  : `Date range`
              }
              onClick={handleStepChange('date')}
            >
              <div className="py-2 px-4">
                <label
                  htmlFor="metrics"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Date range :
                </label>
                <Select
                  onChange={(newValue: any) => {
                    formik.setFieldValue('date', newValue.value);
                  }}
                  name="date"
                  defaultValue={
                    formik.values.date
                      ? {
                        value: formik.values.date,
                        label: toString(formik.values.date),
                      }
                      : null
                  }
                  options={ALL_DATE.map((date) => ({
                    value: date,
                    label: toString(date),
                  }))}
                  classNamePrefix="react-select"
                />
                {formik.values.date === 'CUSTOM' ? (
                  <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 grid-cols-6">
                    <div className="col-span-3">
                      <label
                        htmlFor="date_start"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date Start :
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={(e) => {
                            formik.setFieldValue(
                              'custom_date.start',
                              e.target.value
                            );
                          }}
                          value={formik.values.custom_date?.start}
                          type="date"
                          name="date_start"
                          id="date_start"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="date_end"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date End :
                      </label>
                      <div className="mt-1">
                        <input
                          onChange={(e) =>
                            formik.setFieldValue(
                              'custom_date.end',
                              e.target.value
                            )
                          }
                          disabled={!formik.values.custom_date?.start}
                          value={formik.values.custom_date?.end}
                          min={formik.values.custom_date?.start}
                          type="date"
                          name="date_end"
                          id="date_end"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="flex justify-end mt-4">
                  <Button
                    type="primary-purple"
                    size="x-small"
                    disabled={formik.values.date === 'CUSTOM' && (!formik.values.custom_date?.start || !formik.values.custom_date?.end)}
                    onClick={() => {
                      setStep('filters');
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Collapse>
            <Collapse
              open={step === 'filters'}
              header="Filters"
              subHeader={`${formik.values.filters.length} filters(s)`}
              onClick={handleStepChange('filters')}
            >
              <div className="py-2 px-4">
                {formik.values.filters.map((filter, index) => (
                  <Filter
                    key={`filter-${index}`}
                    fields={fields.data?.data as  FieldDTO[]}
                    value={filter}
                    onRemove={() => {
                      const oldFilterValue = formik.values.filters;
                      oldFilterValue.splice(index, 1);
                      formik.setFieldValue('filters', [...oldFilterValue]);
                    }}
                    onChange={(value) => {
                      const oldFilterValue = formik.values.filters;
                      oldFilterValue[index] = value;
                      formik.setFieldValue('filters', [...oldFilterValue]);
                    }}
                  />
                ))}
                <div className="flex justify-between mt-4">
                  <Button
                    type="transparent"
                    size="x-small"
                    className="flex items-center border border-gray-300"
                    onClick={() => {
                      formik.setFieldValue('filters', [
                        ...formik.values.filters,
                        newFormFilterField(),
                      ]);
                    }}
                  >
                    <PlusIcon className="w-4 h-4 mr-2" />
                    Add field
                  </Button>
                  <Button
                    type="primary-purple"
                    size="x-small"
                    onClick={() => {
                      setStep('options');
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Collapse>
            {configFields.data?.data.length !== undefined && configFields.data?.data.length > 0 ? <Collapse
              open={step === 'options'}
              header="Options"
              subHeader={`${formik.values.filters.length} options(s)`}
              onClick={handleStepChange('options')}
            >
              {configFields.data.data.map(elem => {
                if(elem.getType() === 'multiple') {
                  return (
                    <div className="py-2 px-4">
                      <label
                        htmlFor="metrics"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        {elem.getName()} :
                      </label>
                      <Select
                        isMulti={elem.getAllowedMultiple()}
                        onChange={(newValue) => {
                          formik.setFieldValue(
                            `options.${elem.getId()}`,
                            newValue.map((elem: any) => elem.value)
                          );
                        }}
                        defaultValue={formik.values.options[elem.getId()]?.map((selected: string) => elem.getValue().find((configField) => selected === configField.value))}
                        name={elem.getId()}
                        options={elem.getValue()}
                        classNamePrefix="react-select"
                      />
                      <p className="text-gray-500 text-xs py-1">
                        {elem.getHelpText()}
                      </p>
                    </div>
                  )
                }if(elem.getType() === "checkbox"){
                  return (
                    <div className="py-2 px-4">
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            onChange={(e) => {formik.setFieldValue(`options.${elem.getId()}`, e.target.checked)}}
                            checked={formik.values.options[elem.getId()]}
                            id={elem.getId()}
                            aria-describedby={`${elem.getId()}-description`}
                            name={elem.getId()}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor={elem.getId()} className="font-medium text-gray-900">
                            {elem.getName()}
                          </label>
                          <p id={`${elem.getId()}-description`} className="text-gray-500">
                            {elem.getHelpText()}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}

            </Collapse> : null}
          </form>
        </Async>
      </Async>
      <div className="p-6">
        <ModalSaveQuery loading={savingApiQuery} query={{name: props.query?.getName()}} open={open} onSave={(name) => onSave(name)} onClose={() => setOpen(false)} />
        <div className="flex justify-center items-center space-x-4">
          <Button loading={savingApiQuery} type="primary-inverted" disabled={!props.isValid} onClick={() => setOpen(true)} className="w-full flex justify-center">
            Save the query
          </Button>
          <Button type="danger" onClick={() => onReset()} className="w-full flex justify-center">
            Reset the query
          </Button>
        </div>
      </div>
    </div>
  );
};

export default APIForm;
