import { useMutation, useQuery } from "react-query";
import { assignWith } from "lodash";
import DataStreamAPI from "../../services/repository/data-connector/data-stream/data-stream";
import DataStreamJobAPI from "../../services/repository/data-connector/data-stream/data-stream-job";
import DataStreamModel from "../../services/models/http/data-connector/data-stream/data-stream";
import Pagination from "../../services/models/http/paginated";
import DataStreamJobModel, {
  DataStreamJob
} from "../../services/models/http/data-connector/data-stream/data-stream-job";
import { DataStreamJobFormValue } from "../../features/data-connectors/datastream-job/create";

export const useDeleteDataStreamMutation = (id: number) => useMutation(['data-stream', id], ()  => DataStreamAPI._delete(id));

export const useDeleteJob = (id: number) => useMutation(['data-stream-job-delete', id], ()  => DataStreamJobAPI._delete(id));
export const useDeleteStream = (id: number) => useMutation(['data-stream-delete', id], ()  => DataStreamAPI._delete(id));
export const usePutJob = (id : number) => useMutation<DataStreamJob, unknown, Partial<DataStreamJobFormValue>>(['data-stream', 'job', id], (data)  => DataStreamJobAPI._put(id, {id, ...assignWith({}, data, (_, value) => typeof value === 'undefined' ? null : value)}));
export const useJobRefreshing = (id : number) => useMutation(['data-stream', 'job', id, 'refresh'], ()  => DataStreamJobAPI._refresh(id));
export const useDataStream = (id: number) => useQuery(['data-stream', id], () : Promise<DataStreamModel> => DataStreamAPI._getOne(id));
export const useDataStreamJob = (id: number) => useQuery(['data-stream', 'job', id], () : Promise<DataStreamJobModel> => DataStreamJobAPI._getOne(id), {refetchInterval: 10000});
export const useDataStreamJobListing = (id: number) => useQuery(['data-stream', id, 'jobs'], () : Promise<Pagination<DataStreamJobModel>> => DataStreamJobAPI._getAll({pagination: false, query : { 'datastream': '/api/datastreams/' + id }}));
export const useDataStreamListing = (page: number, options = {}) => useQuery(['data-stream', page, options], () : Promise<Pagination<DataStreamModel>> => DataStreamAPI._getAll({pagination: false, query : {page, ...options}}));
