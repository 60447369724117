import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ArrowCircleUpIcon, CheckIcon, RefreshIcon, TrashIcon } from "@heroicons/react/solid";
import { BriefcaseIcon, CalendarIcon } from "@heroicons/react/outline";
import { useQueryClient } from "react-query";
import { useDataStream, useDataStreamJob, useJobRefreshing, usePutJob } from "../../../../hooks/data-stream";
import Async from "../../../../ui/helper/async";
import NetworkProvider from "../../../../config/network/network-provider";
import Button from "../../../../ui/components/button/button";
import { classNames } from "../../../../services/helper";
import Overview from "./components/overview";
import StepOne from "../step/step-1";
import StepTwo from "../step/step-2";
import StepThree from "../step/step-3";
import {
  DataStreamJob as HTTPDataStreamJob
} from "../../../../services/models/http/data-connector/data-stream/data-stream-job";
import { DataStreamJobFormValue } from "../create";
import StepFour from "../step/step-4";
import { cloneDeep, isEmpty } from "lodash";
import { NotificationManager } from "../../../../ui/components/notification/notification";
import { useTranslation } from "react-i18next";

const tabs = [
  { id: "overview", name: "Overview", href: "#", current: false },
  { id: "basic-option", name: "Job Basic Options", href: "#", current: false },
  { id: "accounts", name: "Accounts", href: "#", current: true },
  { id: "fields", name: "Fields", href: "#", current: false },
  { id: "extraction-option", name: "Extraction Options", href: "#", current: false }
];


const DataStreamJob = () => {
  const params = useParams();
  const {t} = useTranslation();
  const job = useDataStreamJob(params.id_job as unknown as number);
  const datastream = useDataStream(params.id_data_stream as unknown as number);
  const [saving, setSaving] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const mutate = usePutJob(params.id_job as unknown as number);
  const mutateRefresh = useJobRefreshing(params.id_job as unknown as number);
  const [selectedTab, setSelectedTab] = useState(window.location.hash ? window.location.hash.replace("#", "") : "overview");
  const queryClient = useQueryClient();
  const onUpdate = async (value: Partial<DataStreamJobFormValue>) => {
    const httpRequest = cloneDeep(value);
    if(httpRequest.networkAuthorizationId){
      httpRequest.networkAuthorization = `/api/network_authorizations/${value.networkAuthorizationId}`;
    }else{
      httpRequest.networkAuthorization = undefined;
    }
    if(httpRequest.datastreamJobDestinationOption && httpRequest.datastreamJobDestinationOption.id){
      httpRequest.datastreamJobDestinationOption.id = `/api/datastream_job_destination_options/${httpRequest.datastreamJobDestinationOption.id}`;
    }
    setSaving(true);
    try {
      await mutate.mutateAsync(httpRequest, {
        onSuccess: (data) => {
          queryClient.setQueryData(["data-stream", "job", params.id_job], data);
          job.refetch();
        }
      });

      NotificationManager.success(t('The job has been updated.'), t('Your job has been updated.'));
    } catch (e) {
      NotificationManager.error(t('An error occurred.'), t('An internal server error occurred.'));
    }
    setSaving(false);
  };
  const onTabClick = (tab: { id: string }) => {
    window.location.replace(`#${tab.id}`);
  };

  const refresh = async () => {
    setRefreshing(true);
    try {
      await mutateRefresh.mutateAsync();
      job.refetch();
      NotificationManager.success(t('The job has been launched.'), t('Your job has been launched.'));
    } catch (e) {
      NotificationManager.error(t('An error occurred.'), t('An internal server error occurred.'));
    }
    setRefreshing(false);

  }

  useEffect(() => {
    const { hash } = window.location;
    if (hash !== "") {
      setSelectedTab(hash.replace("#", ""));
    }
  }, [window.location.hash]);
  const async: any = {...job, isFetching: false, isLoading: job.isLoading && !job.isPreviousData};
  return (
    <Async {...async} >
      <Async {...datastream}>
        <div className="-mx-6 flex flex-col min-w-0 flex-1 justify-start items-start">
          <div className=" p-4 pb-0 w-full bg-white border-b border-gray-100">
            <div className="flex items-center justify-between">
              <div className="min-w-0 flex-1">
                <h2
                  className="text-2xl flex items-center font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  <img src={NetworkProvider.getByPlatform(datastream.data?.getPlatform() as string)?.networkImage()}
                       className="h-10 w-10" alt="" />
                  <span className="ml-2"> {job.data?.getName()}</span>
                </h2>
                <div className="mt-2 flex flex-col sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {job.data?.getLogs().length} runs
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    Last run
                    at {!isEmpty(job.data?.getLastLaunchedAt()) ? new Date(job.data?.getLastLaunchedAt() as string).toLocaleString() : "Never"}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CheckIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    Last status run : {job.data?.getStatus()}
                  </div>
                </div>
              </div>
              <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <span className="hidden sm:block">
              <Button type="primary" loading={refreshing} onClick={refresh}>
                <RefreshIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                Run Now
              </Button>
          </span>
                <span className="ml-3 hidden sm:block">
            <Button type="danger">
              <TrashIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-white" aria-hidden="true" />
              Delete
            </Button>
          </span>
              </div>
            </div>
            <div className="pt-4 ">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    onClick={(e) => {
                      e.preventDefault();
                      onTabClick(tab);
                    }}
                    className={classNames(
                      selectedTab === tab.id
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium"
                    )}
                    aria-current={selectedTab === tab.id ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>

          <div className="p-4 w-full">
            {selectedTab === "overview" && job.data !== undefined ? <Overview job={job.data} /> : null}
            {selectedTab === "basic-option" && job.data !== undefined ?
              <StepOne saving={saving} defaultValue={job.data.data} onSubmit={onUpdate} /> : null}
            {selectedTab === "accounts" && job.data !== undefined && datastream.data !== undefined ?
              <StepTwo saving={saving} dataStream={datastream.data} defaultValue={job.data.data}
                       onSubmit={onUpdate} /> : null}
            {selectedTab === "fields" && job.data !== undefined && datastream.data !== undefined ?
              <StepThree saving={saving} currentValue={{
                authorizations: job.data.data.sources.map(elem => ({
                  authorizationId: elem.authorization.id,
                  id: elem.accountId
                }))
              }} dataStream={datastream.data} defaultValue={job.data.data} onSubmit={onUpdate} /> : null}
            {selectedTab === "extraction-option" && job.data !== undefined && datastream.data !== undefined ?
              <StepFour dataStream={datastream.data} saving={saving} defaultValue={job.data.data} onSubmit={onUpdate} /> : null}
          </div>
        </div>
      </Async>
    </Async>
  );
};

export default DataStreamJob;
